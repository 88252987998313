import { useState } from "react";

import walletLogo from "../assets/picture/Wallet.svg";

import arrowUpRightLogo from "../assets/picture/ArrowUpRight.svg";
import frame from "../assets/picture/Frame24.png";
import arrow from "../assets/picture/arrow.png";
import caretDown from "../assets/picture/CaretDown.png";
import Divider from "../components/Divider";
import MarqueeComponent from "../components/MarqueeComponent";
import Logo from "../components/Logo";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { nftAddress, abi } from "../utils";
import { toast } from "react-toastify";
import Countdown from "react-countdown";

type HomeProps = {};

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <div className="flex flex-row gap-x-[10px]">
      <div className="flex flex-row items-end gap-x-[10px]">
        <span
          className={`${
            completed ? "text-red-500" : ""
          } text-[36px] sm:text-[64px] lg:text-[96px]  lexend-500`}
        >
          {(days * 24 + hours)?.toString().padStart(2, "0")}
        </span>
        <span className="text-[14px] leading-[10px] lg:mb-10 sm:mb-6 mb-3">
          HH
        </span>
      </div>
      <div className="flex items-end gap-x-[10px]">
        <span
          className={`${
            completed ? "text-red-500" : ""
          } text-[36px] sm:text-[64px] lg:text-[96px]  lexend-500`}
        >
          {minutes?.toString().padStart(2, "0")}
        </span>
        <span className="text-[14px] leading-[10px] lg:mb-10 sm:mb-6 mb-3">
          MM
        </span>
      </div>
      <div className="flex flex-row items-end gap-x-[10px]">
        <span
          className={`${
            completed ? "text-red-500" : ""
          } text-[36px] sm:text-[64px] lg:text-[96px]  lexend-500`}
        >
          {seconds?.toString().padStart(2, "0")}
        </span>
        <span className="text-[14px] leading-[10px] lg:mb-10 sm:mb-6 mb-3">
          SS
        </span>
      </div>
    </div>
  );
};

const Home: React.FC<HomeProps> = () => {
  // const [open, setOpen] = useState<boolean>(false);
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  // @ts-ignore
  const { data: totalMinted }: any = useReadContract({
    abi: abi,
    address: nftAddress,
    functionName: "totalMinted",
    query: {
      refetchInterval: 1000,
    },
  } as const);

  // @ts-ignore
  const { data: maxMintPerPerson }: any = useReadContract({
    abi: abi,
    address: nftAddress,
    functionName: "MAX_MINT_PER_PERSON",
    args: [],
    query: {
      refetchInterval: 1000,
    },
  } as const);

  // @ts-ignore
  const { data: minted }: any = useReadContract({
    abi: abi,
    address: nftAddress,
    functionName: "minted",
    args: [address],
    query: {
      refetchInterval: 1000,
    },
  } as const);

  // @ts-ignore
  const { writeContractAsync } = useWriteContract();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [maxMintCount] = useState<number>(500);
  const [reachedMaxTime, setReachedMaxTime] = useState<boolean>(false);

  // future date to 12:00:00 AM on April 4st, 2024
  const [futureDate] = useState<Date>(new Date("2024-04-20T16:00:00.000Z"));

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-between lg:flex-row lg:items-center site_padding">
        <Logo />
        <div className="flex flex-row items-end gap-x-[20px]">
          {false && (
            <div className="hidden md:block">
              <div className="flex flex-col items-center gap-y-[10px]">
                <button
                  disabled={
                    reachedMaxTime ||
                    Number(totalMinted) >= maxMintCount ||
                    minted >= maxMintPerPerson
                  }
                  className={`${
                    reachedMaxTime ||
                    Number(totalMinted) >= maxMintCount ||
                    minted >= maxMintPerPerson
                      ? "bg-gray-200"
                      : "bg-flame"
                  } flex flex-row items-center justify-center rounded-full  w-[200px] h-[50px] gap-x-[10px] cursor-pointer`}
                  onClick={async () => {
                    try {
                      console.log("clicking");
                      setIsLoading(true);
                      //@ts-ignore
                      await writeContractAsync({
                        abi: abi,
                        address: nftAddress,
                        functionName: "safeMint",
                        args: [],
                      });

                      setIsLoading(false);
                      toast.success("NFT Minted Successfully");
                    } catch (e: any) {
                      console.log(e);
                      setIsLoading(false);
                      toast.error(
                        "Error Minting NFT: " + e.shortMessage?.split("\n")?.[1]
                      );
                    }
                  }}
                >
                  <span className="text-white">
                    {isLoading ? "Minting..." : "Mint NFT"}
                  </span>
                  <img src={arrowUpRightLogo} alt="" />
                </button>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center gap-y-[10px]">
            {!isConnected && (
              <span className="">Connect your wallet to join the Mint</span>
            )}
            {isConnected ? (
              <div className="flex gap-3 sm:items-center">
                <button
                  disabled={
                    reachedMaxTime ||
                    Number(totalMinted) >= maxMintCount ||
                    minted >= maxMintPerPerson
                  }
                  onClick={async () => {
                    try {
                      console.log("clicking");
                      setIsLoading(true);
                      //@ts-ignore
                      await writeContractAsync({
                        abi: abi,
                        address: nftAddress,
                        functionName: "safeMint",
                        args: [],
                      });

                      setIsLoading(false);
                      toast.success("NFT Minted Successfully");
                    } catch (e: any) {
                      console.log(e);
                      setIsLoading(false);
                      toast.error(
                        "Error Minting NFT: " + e.shortMessage?.split("\n")?.[1]
                      );
                    }
                  }}
                  className={`${
                    reachedMaxTime ||
                    Number(totalMinted) >= maxMintCount ||
                    minted >= maxMintPerPerson
                      ? "bg-gray-200"
                      : "bg-flame"
                  } flex flex-row items-center justify-center rounded-full  w-[200px] h-[50px] gap-x-[10px] cursor-pointer`}
                >
                  <span className="text-white">
                    {isLoading ? "MINTING..." : "MINT NFT"}{" "}
                  </span>
                  <img src={arrow} alt="" />
                </button>
                <div className="fixed left-0 z-40 flex items-center justify-center w-full sm:static bottom-10">
                  <div className="bg-white flex flex-row items-center justify-center rounded-full max-w-[300px] h-[50px] gap-x-[10px] cursor-pointer border px-3 shadow-sm">
                    <div className="w-5 h-5 rounded-full">
                      <img
                        src={frame}
                        className="w-full h-full"
                        alt="profile"
                      />
                    </div>
                    <span className="" onClick={() => open()}>
                      {address?.replace(address?.slice(5, 39), "****")}
                    </span>
                    <img src={caretDown} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              <div
                onClick={() => open()}
                className="flex flex-row items-center justify-center rounded-full bg-flame max-w-[300px] w-full h-[50px] gap-x-[10px] cursor-pointer"
              >
                <span className="text-white">
                  {isConnected
                    ? address?.replace(address?.slice(5, 39), "****")
                    : "Connect Wallet"}
                </span>
                <img src={walletLogo} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="site_padding">
        <Divider classes="md:my-[50px] my-5"/>
      </div>
      <MarqueeComponent />

      <div className="w-full flex flex-col items-center justify-center gap-y-[10px] mt-4 site_padding">
        <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-end">
          <span className="text-[22px] md:text-[36px] md:mb-3">MINTED</span>
          <span
            className={`text-[36px] sm:text-[64px] lg:text-[96px] lexend-500 ${
              Number(totalMinted) >= maxMintCount ? "text-red-500" : ""
            }`}
          >
            {Number(totalMinted?.toString() || 0)}/{maxMintCount}
          </span>
        </div>
        <Divider classes="mt-0 mb-5 md:mb-[50px]" />
        <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-end gap-y-[10px]">
          <span className="text-[22px] md:text-[36px] md:mb-3">
            MINT REMAINING TIME
          </span>
          <Countdown
            date={futureDate}
            renderer={renderer}
            onComplete={() => {
              setReachedMaxTime(true);
            }}
          />
        </div>
        <Divider classes="mt-0 mb-5 md:mb-[50px]" />
      </div>

      {isConnected && (
        <div className="block md:hidden site_padding">
          <div className="flex flex-col items-center gap-y-[10px] mt-[50px]">
            <button
              disabled={
                reachedMaxTime ||
                Number(totalMinted) >= maxMintCount ||
                minted >= maxMintPerPerson
              }
              onClick={async () => {
                try {
                  console.log("clicking");
                  setIsLoading(true);
                  //@ts-ignore
                  await writeContractAsync({
                    abi: abi,
                    address: nftAddress,
                    functionName: "safeMint",
                    args: [],
                  });

                  setIsLoading(false);
                  toast.success("NFT Minted Successfully");
                } catch (e: any) {
                  console.log(e);
                  setIsLoading(false);
                  toast.error(
                    "Error Minting NFT: " + e.shortMessage?.split("\n")?.[1]
                  );
                }
              }}
              className={`${
                reachedMaxTime || Number(totalMinted) >= maxMintCount || minted
                  ? "bg-gray-200"
                  : "bg-flame"
              } flex flex-row items-center justify-center rounded-full  w-[200px] h-[50px] gap-x-[10px] cursor-pointer`}
            >
              <span className="text-white">
                {isLoading ? "Minting..." : "Mint NFT"}
              </span>
              <img src={arrowUpRightLogo} alt="" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
