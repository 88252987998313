import React from 'react';

interface DividerProps {
  classes?: string; 
}

const Divider: React.FC<DividerProps> = ({ classes }) => {
  return <div className={`w-full my-[50px] h-[1px] bg-[#E6E5E5] ${classes}`}></div>;
};

export default Divider;
