import slideLogo1 from "../picture/slide-images/1.png";
import slideLogo2 from "../picture/slide-images/2.png";
import slideLogo3 from "../picture/slide-images/3.png";
import slideLogo4 from "../picture/slide-images/4.png";
import slideLogo5 from "../picture/slide-images/5.png";
import slideLogo6 from "../picture/slide-images/6.png";
import { TimeData } from "./types";

export const slideImages = [
  { image: slideLogo1 },
  { image: slideLogo2 },
  { image: slideLogo3 },
  { image: slideLogo4 },
  { image: slideLogo5 },
  { image: slideLogo6 },
  { image: slideLogo1 },
  { image: slideLogo2 },
  { image: slideLogo3 },
  { image: slideLogo4 },
];

export const formatTime = (timeInSeconds: number): TimeData => {
  const days = Math.floor(timeInSeconds / (60 * 60 * 24));
  const hours = Math.floor((timeInSeconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((timeInSeconds % (60 * 60)) / 60);
  const seconds = timeInSeconds % 60;
  return { days, hours, minutes, seconds };
};
